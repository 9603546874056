@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-Italic.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-Light.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-LightItalic.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-Medium.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-MediumItalic.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-Thin.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-ThinItalic.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-UltraLight.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-UltraLight.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-UltraLightItalic.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-UltraLightItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-Bold.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/helvetica-neue/HelveticaNeue-BoldItalic.woff2') format('woff2'),
        url('/src/fonts/helvetica-neue/HelveticaNeue-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

